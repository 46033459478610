import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="bard"></a>BARD</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG27_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Any nonlawful.</p>
    <p><b>Hit Die</b>: d6.</p>
    <h6>Class Skills</h6>
    <p className="initial">The bard&rsquo;s class skills (and the key ability
for each skill) are <a href="skillsAll.html#appraise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Appraise</a>
(Int), <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a> (Dex), <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
(Cha), <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
(Str), <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#decipher-script" style={{
        "color": "rgb(87, 158, 182)"
      }}>Decipher Script</a> (Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
(Cha), <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a> (Cha), <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape
Artist</a> (Dex), <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> (Cha), <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
(Dex), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (all skills, taken
individually) (Int), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> (Wis), <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> (Dex), <a href="skillsAll.html#perform" style={{
        "color": "rgb(87, 158, 182)"
      }}>Perform</a> (Cha), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> (Wis), <a href="skillsAll.html#sleight-of-hand" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sleight
of Hand</a> (Dex), <a href="skillsAll.html#speak-language" style={{
        "color": "rgb(87, 158, 182)"
      }}>Speak Language</a> (n/a), <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a>
(Int), <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
(Str), <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tumble</a> (Dex), and <a href="skillsAll.html#use-magic-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Magic Device</a> (Cha).</p>
    <p><b>Skill Points at 1st Level</b>: (6 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 6 + Int modifier.</p>
    <p><a id="table-the-bard"></a><b>Table: The Bard</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="2" style={{
            "width": "40%"
          }}>Special</th>
          <th colSpan="7" style={{
            "textAlign": "center"
          }}>Spells per Day</th>
        </tr>
        <tr>
          <th style={{
            "width": "3%"
          }}>0</th>
          <th style={{
            "width": "3%"
          }}>1st</th>
          <th style={{
            "width": "3%"
          }}>2nd</th>
          <th style={{
            "width": "3%"
          }}>3rd</th>
          <th style={{
            "width": "3%"
          }}>4th</th>
          <th style={{
            "width": "3%"
          }}>5th</th>
          <th style={{
            "width": "3%"
          }}>6th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>+2</td>
          <td>Bardic music, bardic knowledge, countersong, fascinate,
inspire&nbsp;courage&nbsp;+1</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+3</td>
          <td>+3</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>0</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+3</td>
          <td>+3</td>
          <td>Inspire competence</td>
          <td>3</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>3</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>Suggestion</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+2</td>
          <td>+6</td>
          <td>+6</td>
          <td>Inspire courage +2</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+3</td>
          <td>+6</td>
          <td>+6</td>
          <td>Inspire greatness</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2</td>
          <td>+3</td>
          <td>+7</td>
          <td>+7</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>+7</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>+8</td>
          <td>Song of freedom</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>+8</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+4</td>
          <td>+9</td>
          <td>+9</td>
          <td>Inspire courage + 3</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>1</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+5</td>
          <td>+9</td>
          <td>+9</td>
          <td>Inspire heroics</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+10</td>
          <td>+10</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+10</td>
          <td>+10</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>1</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+6</td>
          <td>+11</td>
          <td>+11</td>
          <td>Mass suggestion</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+6</td>
          <td>+11</td>
          <td>+11</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">+12</td>
          <td className="last-row">Inspire courage +4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
        </tr>
      </tbody>
    </table>
    <p><a id="table-bard-spells-known"></a><b>Table: Bard Spells Known</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr>
          <th>&nbsp;</th>
          <th colSpan="7" style={{
            "textAlign": "center"
          }}>Spells Known</th>
        </tr>
        <tr>
          <th>Level</th>
          <th style={{
            "width": "12%"
          }}>&nbsp;0&nbsp;</th>
          <th style={{
            "width": "12%"
          }}>1st</th>
          <th style={{
            "width": "12%"
          }}>2nd</th>
          <th style={{
            "width": "12%"
          }}>3rd</th>
          <th style={{
            "width": "12%"
          }}>4th</th>
          <th style={{
            "width": "12%"
          }}>5th</th>
          <th style={{
            "width": "12%"
          }}>6th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>4</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>5</td>
          <td>2<sup>1</sup></td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>6</td>
          <td>3</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>6</td>
          <td>3</td>
          <td>2<sup>1</sup></td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>6</td>
          <td>4</td>
          <td>3</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>6</td>
          <td>4</td>
          <td>3</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>2<sup>1</sup></td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>2<sup>1</sup></td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>2<sup>1</sup></td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>6</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>6</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>2<sup>1</sup></td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>6</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">6</td>
          <td className="last-row">5</td>
          <td className="last-row">5</td>
          <td className="last-row">5</td>
          <td className="last-row">5</td>
          <td className="last-row">5</td>
          <td className="last-row">4</td>
        </tr>
        <tr>
          <td colSpan="8">1 <i>Provided the bard has a high enough
Charisma score to have a bonus spell of this level.</i></td>
        </tr>
      </tbody>
    </table>
    <h6><a id="bard-spell-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
bard.</p>
    <p><b>Weapon and Armor Proficiency</b>: A bard is proficient with
all simple weapons, plus the longsword, rapier, sap, short sword,
shortbow, and whip. Bards are proficient with light armor and shields
(except tower shields). A bard can cast bard spells while wearing light
armor without incurring the normal arcane spell failure chance.
However, like any other arcane spellcaster, a bard wearing medium or
heavy armor or using a shield incurs a chance of arcane spell failure
if the spell in question has a somatic component (most do). A
multiclass bard still incurs the normal arcane spell failure chance for
arcane spells received from other classes.</p>
    <p><b>Spells</b>: A bard casts arcane spells, which are drawn
from the <a style={{
        "color": "#579eb6"
      }} href="bardSpells.html">bard spell list</a>. He can cast any spell he knows without
preparing it ahead of time. Every bard spell has a verbal component
(singing, reciting, or music).</p>
    <p>To learn or cast a spell, a bard must have a Charisma score
equal to at least 10 + the spell. The Difficulty Class for a saving
throw against a bard&rsquo;s spell is 10 + the spell level + the bard&rsquo;s
Charisma modifier.</p>
    <p>Like other spellcasters, a bard can cast only a certain number
of spells of each spell level per day. His base daily spell allotment
is given on Table: The Bard. In addition, he receives bonus spells per
day if he has a high Charisma score. When Table: Bard Spells Known
indicates that the bard gets 0 spells per day of a given spell level,
he gains only the bonus spells he would be entitled to based on his
Charisma score for that spell level.</p>
    <p>The bard&rsquo;s selection of spells is extremely limited. A bard
begins play knowing four 0-level spells of your choice. At most new
bard levels, he gains one or more new spells, as indicated on Table:
Bard Spells Known. (Unlike spells per day, the number of spells a bard
knows is not affected by his Charisma score; the numbers on Table: Bard
Spells Known are fixed.)</p>
    <p>Upon reaching 5th level, and at every third bard level after
that (8th, 11th, and so on), a bard can choose to learn a new spell in
place of one he already knows. In effect, the bard &ldquo;loses&rdquo; the old
spell in exchange for the new one. The new spell&rsquo;s level must be the
same as that of the spell being exchanged, and it must be at least two
levels lower than the highest-level bard spell the bard can cast. A
bard may swap only a single spell at any given level, and must choose
whether or not to swap the spell at the same time that he gains new
spells known for the level.</p>
    <p>As noted above, a bard need not prepare his spells in advance.
He can cast any spell he knows at any time, assuming he has not yet
used up his allotment of spells per day for the spell&rsquo;s level.</p>
    <p><b><a id="bardic-knowledge"></a>Bardic Knowledge</b>: A bard
may make a special bardic knowledge check with a bonus equal to his
bard level + his Intelligence modifier to see whether he knows some
relevant information about local notable people, legendary items, or
noteworthy places. (If the bard has 5 or more ranks in Knowledge
(history), he gains a +2 bonus on this check.)</p>
    <p>A successful bardic knowledge check will not reveal the powers
of a magic item but may give a hint as to its general function. A bard
may not take 10 or take 20 on this check; this sort of knowledge is
essentially random.</p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "30px"
          }}><a id="table-bardic-knowledge"></a>DC</th>
          <th>Type of Knowledge</th>
        </tr>
        <tr className="odd-row">
          <td>10</td>
          <td>Common, known by at least a substantial minority
drinking; common legends of the local population.</td>
        </tr>
        <tr>
          <td>20</td>
          <td>Uncommon but available, known by only a few people
legends.</td>
        </tr>
        <tr className="odd-row">
          <td>25</td>
          <td>Obscure, known by few, hard to come by.</td>
        </tr>
        <tr>
          <td className="last-row">30</td>
          <td className="last-row">Extremely obscure, known by very few,
possibly forgotten by most who once knew it, possibly known only by
those who don&rsquo;t understand the significance of the knowledge.</td>
        </tr>
      </tbody>
    </table>
    <p><b><a id="bardic-music"></a>Bardic Music</b>: Once per day
per bard level, a bard can use his song or poetics to produce magical
effects on those around him (usually including himself, if desired).
While these abilities fall under the category of bardic music and the
descriptions discuss singing or playing instruments, they can all be
activated by reciting poetry, chanting, singing lyrical songs, singing
melodies, whistling, playing an instrument, or playing an instrument in
combination with some spoken performance. Each ability requires both a
minimum bard level and a minimum number of ranks in the Perform skill
to qualify; if a bard does not have the required number of ranks in at
least one Perform skill, he does not gain the bardic music ability
until he acquires the needed ranks.</p>
    <p>Starting a bardic music effect is a standard action. Some
bardic music abilities require concentration, which means the bard must
take a standard action each round to maintain the ability. Even while
using bardic music that doesn&rsquo;t require concentration, a bard cannot
cast spells, activate magic items by spell completion (such as
scrolls), or activate magic items by magic word (such as wands). Just
as for casting a spell with a verbal component, a deaf bard has a 20%
chance to fail when attempting to use bardic music. If he fails, the
attempt still counts against his daily limit.</p>
    <p><i><a id="countersong"></a>Countersong (Su)</i>: A bard with
3 or more ranks in a Perform skill can use his music or poetics to
counter magical effects that depend on sound (but not spells that
simply have verbal components). Each round of the countersong, he makes
a Perform check. Any creature within 30 feet of the bard (including the
bard himself) that is affected by a sonic or language-dependent
magical attack may use the bard&rsquo;s Perform check result in place of its
saving throw if, after the saving throw is rolled, the Perform check
result proves to be higher. If a creature within range of the
countersong is already under the effect of a noninstantaneous sonic or
language-dependent magical attack, it gains another saving throw
against the effect each round it hears the countersong, but it must use
the bard&rsquo;s Perform check result for the save. Countersong has no effect
against effects that don&rsquo;t allow saves. The bard may keep up the
countersong for 10 rounds.</p>
    <p><i><a id="bard-fascinate"></a><a id="fascinate"></a>Fascinate (Sp)</i>: A bard with 3
or more ranks in a Perform skill can use his music or poetics to cause
one or more creatures to become <a href="abilitiesAndConditions.html#fascinated" style={{
        "color": "rgb(87, 158, 182)"
      }}>fascinated</a> with him. Each
creature to
be fascinated must be within 90 feet, able to see and hear the bard,
and able to pay attention to him. The bard must also be able to see the
creature. The distraction of a nearby combat or other dangers prevents
the ability from working. For every three levels a bard attains beyond
1st, he can target one additional creature with a single use of this
ability.</p>
    <p>To use the ability, a bard makes a Perform check. His check
result is the DC for each affected creature&rsquo;s Will save against the
effect. If a creature&rsquo;s saving throw succeeds, the bard cannot attempt
to fascinate that creature again for 24 hours. If its saving throw
fails, the creature sits quietly and listens to the song, taking no
other actions, for as long as the bard continues to play and
concentrate (up to a maximum of 1 round per bard level). While
fascinated, a target takes a &ndash;4 penalty on skill checks made as
reactions, such as Listen and Spot checks. Any potential threat
requires the bard to make another Perform check and allows the creature
a new saving throw against a DC equal to the new Perform check result.</p>
    <p>Any obvious threat, such as someone drawing a weapon, casting
a spell, or aiming a ranged weapon at the target, automatically breaks
the effect. Fascinate is an enchantment (compulsion), mind-affecting
ability.</p>
    <p><i><a id="inspire-courage"></a>Inspire Courage (Su)</i>: A
bard with 3 or more ranks in a Perform skill can use song or poetics to
inspire courage in his allies (including himselfs), bolstering them
against fear and improving their combat abilities. To be affected, an
ally must be able to hear the bard sing. The effect lasts for as long
as the ally hears the bard sing and for 5 rounds thereafter. An
affected ally receives a +1 morale bonus on saving throws against charm
and fear effects and a +1 morale bonus on attack and weapon damage
rolls. At 8th level, and every six bard levels thereafter, this bonus
increases by 1 (+2 at 8th, +3 at 14th, and +4 at 20th). Inspire courage
is a mind-affecting ability.</p>
    <p><i><a id="inspire-competence"></a>Inspire Competence (Su)</i>:
A bard of 3rd level or higher with 6 or more ranks in a Perform skill
can use his music or poetics to help an ally succeed at a task. The
ally must be within 30 feet and able to see and hear the bard. The bard
must also be able to see the ally.</p>
    <p>The ally gets a +2 competence bonus on skill checks with a
particular skill as long as he or she continues to hear the bard&rsquo;s
music. Certain uses of this ability are infeasible. The effect lasts as
long as the bard concentrates, up to a maximum of 2 minutes. A bard
can&rsquo;t inspire competence in himself. Inspire competence is a
mind-affecting ability.</p>
    <p><i><a id="bard-suggestion"></a><a id="suggestion"></a>Suggestion (Sp)</i>: A bard of 6th
level or higher with 9 or more ranks in a Perform skill can make a
suggestion (as the spell) to a creature that he has already <a href="abilitiesAndConditions.html#fascinated" style={{
        "color": "rgb(87, 158, 182)"
      }}>fascinated</a>
(see above). Using this ability does not break the bard&rsquo;s concentration
on the fascinate effect, nor does it allow a second saving throw
against the fascinate effect.</p>
    <p>Making a suggestion doesn&rsquo;t count against a bard&rsquo;s daily limit
on bardic music performances. A Will saving throw (DC 10 + 1/2 bard&rsquo;s
level + bard&rsquo;s Cha modifier) negates the effect. This ability affects
only a single creature (but see mass suggestion, below). Suggestion is
an enchantment (compulsion), mind-affecting, language dependent ability.</p>
    <p><i><a id="inspire-greatness"></a>Inspire Greatness (Su)</i>:
A bard of 9th level or higher with 12 or more ranks in a Perform skill
can use music or poetics to inspire greatness in himself or a single
willing ally within 30 feet, granting him or her extra fighting
capability. For every three levels a bard attains beyond 9th, he can
target one additional ally with a single use of this ability (two at
12th level, three at 15th, four at 18th). To inspire greatness, a bard
must sing and an ally must hear him sing. The effect lasts for as long
as the ally hears the bard sing and for 5 rounds thereafter. A creature
inspired with greatness gains 2 bonus Hit Dice (d10s), the commensurate
number of temporary hit points (apply the target&rsquo;s Constitution
modifier, if any, to these bonus Hit Dice), a +2 competence bonus on
attack rolls, and a +1 competence bonus on Fortitude saves. The bonus
Hit Dice count as regular Hit Dice for determining the effect of spells
that are Hit Dice dependant. Inspire greatness is a mind-affecting
ability.</p>
    <p><i><a id="song-of-freedom"></a>Song of Freedom (Sp)</i>: A
bard of 12th level or higher with 15 or more ranks in a Perform skill
can use music or poetics to create an effect equivalent to the break
enchantment spell (caster level equals the character&rsquo;s bard level).
Using this ability requires 1 minute of uninterrupted concentration and
music, and it functions on a single target within 30 feet. A bard can&rsquo;t
use song of freedom on himself.</p>
    <p><i><a id="inspire-heroics"></a>Inspire Heroics (Su)</i>: A
bard of 15th level or higher with 18 or more ranks in a Perform skill
can use music or poetics to inspire tremendous heroism in himself or a
single willing ally within 30 feet. For every three bard levels the
character attains beyond 15th, he can inspire heroics in one additional
creature. To inspire heroics, a bard must sing and an ally must hear
the bard sing for a full round. A creature so inspired gains a +4
morale bonus on saving throws and a +4 dodge bonus to AC. The effect
lasts for as long as the ally hears the bard sing and for up to 5
rounds thereafter. Inspire heroics is a mind-affecting ability.</p>
    <p><i><a id="bard-mass-suggestion"></a><a id="mass-suggestion"></a>Mass Suggestion (Sp)</i>:
This ability functions like suggestion, above, except that a bard of
18th level or higher with 21 or more ranks in a Perform skill can make
the suggestion simultaneously to any number of creatures that he has
already <a href="abilitiesAndConditions.html#fascinated" style={{
        "color": "rgb(87, 158, 182)"
      }}>fascinated</a> (see above). Mass
suggestion is an enchantment
(compulsion), mind-affecting, language-dependent ability.</p>
    <h6><a id="ex-bards"></a>Ex-Bards</h6>
    <p className="initial">A bard who becomes lawful in alignment cannot
progress in levels as a bard, though he retains all his bard abilities.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      